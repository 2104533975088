import {
	type HeadersFunction,
	type LinksFunction,
	type MetaFunction,
	json,
	type LoaderFunctionArgs,
	type SerializeFrom,
} from '@remix-run/node';
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	type ShouldRevalidateFunction,
	useLoaderData,
	useRouteError,
	ScrollRestoration,
} from '@remix-run/react';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { href as iconsHref } from './components/ui/icon.tsx';
import { ShowToast, Toaster } from './components/ui/toaster.tsx';
import tailwindStylesheetUrl from './styles/tailwind.css?url';
import { getClientEnv } from './utils/env.server.ts';
import { useNonce } from './utils/nonce-provider.ts';
import { GlobalProgressIndicator } from './components/global-progress-indicator.tsx';
import { makeTimings, time } from './utils/timing.server.ts';
import { cn, useRouteLoaderDataTyped } from './utils/misc.ts';
import {
	AppModeProvider,
	useAppMode,
} from './components/dashboard/app-mode-provider.tsx';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import {
	RootHandleProvider,
	useRootHandle,
} from './components/dashboard/root-handle-provider.tsx';
import { TooltipProvider } from './components/ui/tooltip.tsx';
import { SentryContext } from './components/sentry-context.tsx';
import { themeClassName } from './common/constants/theme.tsx';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import {
	createQueryClient,
	useQueryClientState,
} from './utils/query-client.ts';
import { useState } from 'react';
import { SegmentTracker } from './components/analytics/segment-tracker.client.tsx';

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		// Preload CSS as a resource to avoid render blocking
		{ rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
		{
			rel: 'alternate icon',
			type: 'image/png',
			href: '/favicons/favicon-32x32.png',
		},
		{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		{ rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
		{ rel: 'stylesheet', href: tailwindStylesheetUrl },
	].filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? 'hostU | Student Housing' : 'Error | host U' },
		{
			name: 'description',
			content:
				'hostU is a platform that facilitates medium-term shared housing rentals within the university community, connecting students and faculty in need of housing with those looking to rent out their space.',
		},

		{ property: 'og:title', content: 'hostU | Student Housing' },
		{
			property: 'og:description',
			content:
				'hostU is a platform that facilitates medium-term shared housing rentals within the university community, connecting students and faculty in need of housing with those looking to rent out their space.',
		},
		{ property: 'og:url', content: 'https://joinhostu.com' },
		{
			property: 'og:image',
			content: 'https://i.ibb.co/Ms3St5m/host-U-Social-Media.png',
		},
		{ property: 'og:image:width', content: '1200' },
		{ property: 'og:image:height', content: '630' },

		{ name: 'twitter:card', content: 'summary_large_image' },
		{ name: 'twitter:title', content: 'hostU | Student Housing' },
		{
			name: 'twitter:description',
			content:
				'hostU is a platform that facilitates medium-term shared housing rentals within the university community, connecting students and faculty in need of housing with those looking to rent out their space.',
		},
		{
			name: 'twitter:image',
			content: 'https://i.ibb.co/Ms3St5m/host-U-Social-Media.png',
		},
	];
};

export const shouldRevalidate: ShouldRevalidateFunction = ({
	defaultShouldRevalidate,
	currentUrl,
	nextUrl,
	formAction,
	formMethod,
}) => {
	if (
		(!formMethod || formMethod === 'GET') &&
		currentUrl.pathname === nextUrl.pathname
	) {
		return false;
	}

	if (currentUrl.pathname !== nextUrl.pathname) {
		return defaultShouldRevalidate;
	}

	if (formAction) {
		return false;
	}

	return defaultShouldRevalidate;
};

export function useRootLoaderData() {
	return useRouteLoaderDataTyped<typeof loader>('root');
}

export function useIsAuthenticated() {
	const data = useRootLoaderData();
	return !!data.session;
}

export async function loader({ context }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader');
	const session = await time(() => context.authSession.getOptionalSession(), {
		type: 'root load auth',
		timings,
	});

	return json(
		{
			ENV: getClientEnv(context.env),
			session: !!session,
		},
		{
			headers: { 'Server-Timing': timings.toString() },
		},
	);
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	};
	return headers;
};

function Document({
	children,
	nonce,
	loaderEnv,
}: {
	children: React.ReactNode;
	nonce: string;
	loaderEnv?: SerializeFrom<typeof loader>['ENV'];
}) {
	const handle = useRootHandle();
	const mode = useAppMode();
	return (
		<html lang="en" className={`h-full overflow-x-hidden`}>
			<head>
				<Meta />
				<meta charSet="utf-8" />
				<meta
					suppressHydrationWarning
					name="viewport"
					content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1"
				/>
				<Links />
			</head>
			<body
				className={cn(
					'flex h-full flex-col bg-background text-foreground',
					mode === 'host' ? themeClassName.host : themeClassName.guest,
					handle?.bodyClass,
				)}
			>
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `
window.SENTRY=${JSON.stringify(loaderEnv?.SENTRY ?? {})};
window.MODE='${loaderEnv?.MODE ?? ('production' as typeof MODE)}';`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	);
}

function App() {
	const data = useLoaderData<typeof loader>();
	const nonce = useNonce();

	return (
		<RootHandleProvider>
			<AppModeProvider>
				<TooltipProvider>
					<Document nonce={nonce} loaderEnv={data.ENV}>
						<AppQueryClientProvider>
							<GlobalProgressIndicator />
							<Outlet />
							<Toaster />
							<ShowToast />
							{SegmentTracker ? <SegmentTracker /> : null}
							<SentryContext />
						</AppQueryClientProvider>
					</Document>
				</TooltipProvider>
			</AppModeProvider>
		</RootHandleProvider>
	);
}

function AppQueryClientProvider({ children }: { children: React.ReactNode }) {
	const [queryClient] = useState(() => createQueryClient());
	const queryClientState = useQueryClientState();

	return (
		<QueryClientProvider client={queryClient}>
			<HydrationBoundary state={queryClientState}>{children}</HydrationBoundary>
		</QueryClientProvider>
	);
}

export default withSentry(App);

export function ErrorBoundary() {
	const nonce = useNonce();

	const error = useRouteError();
	captureRemixErrorBoundaryError(error);

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	);
}
