import { useEnv } from '~/utils/env.js';
import { useOptionalUser } from '~/utils/user.js';
import { setUser } from '@sentry/remix';
import { useEffect } from 'react';

export function SentryContext() {
	const env = useEnv();
	const user = useOptionalUser() ?? {
		id: undefined,
		firstName: undefined,
		lastName: undefined,
		email: undefined,
	};

	useEffect(() => {
		if (!env.SENTRY?.DSN) {
			return;
		}

		setUser({
			id: user.id,
			username: user.id ? `${user.firstName} ${user.lastName}` : undefined,
			email: user.email,
		});
	}, [env.SENTRY?.DSN, user?.id, user?.firstName, user?.lastName, user?.email]);

	return null;
}
