import { useNavigation } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import { useSpinDelay } from 'spin-delay';
import { cn } from '~/utils/misc.ts';

export function GlobalProgressIndicator() {
	const { state: navigationState, formAction } = useNavigation();

	const navigating = useSpinDelay(navigationState !== 'idle', {
		delay: 200,
	});

	const ref = useRef<HTMLDivElement>(null);
	const [animationComplete, setAnimationComplete] = useState(true);

	useEffect(() => {
		if (!ref.current) return;
		if (navigating) setAnimationComplete(false);

		const animations =
			'getAnimations' in ref.current ? ref.current?.getAnimations() : [];

		Promise.allSettled(animations).then(() => {
			if (navigating) return;
			setAnimationComplete(true);
		});
	}, [navigating]);

	return (
		<div
			role="progressbar"
			aria-hidden={!navigating}
			aria-valuetext={navigating ? 'Loading' : undefined}
			className={cn('absolute inset-x-0 top-0 z-50 h-1', {
				hidden: !!formAction,
			})}
		>
			<div
				ref={ref}
				className={cn('h-full bg-primary transition-all ease-in-out', {
					'w-0 transition-none':
						navigationState === 'idle' && animationComplete,
					'w-4/12 duration-500': navigationState === 'submitting',
					'w-10/12 duration-500': navigationState === 'loading',
					'w-full opacity-0 duration-300':
						navigationState === 'idle' && !animationComplete,
				})}
			></div>
		</div>
	);
}
