import { useMatches } from '@remix-run/react';
import { createContext, useContext, useMemo } from 'react';

export type RootHandle = { bodyClass: string };

const rootHandleContext = createContext<RootHandle | null>(null);

export function useRootHandle() {
	return useContext(rootHandleContext);
}

export function isRootHandle(handle: unknown): handle is RootHandle {
	return !!handle && typeof handle === 'object' && 'bodyClass' in handle;
}

export function RootHandleProvider({
	children,
}: {
	children: React.ReactNode;
}) {
	const matches = useMatches();
	const leafHandle: RootHandle | undefined = useMemo(
		() =>
			matches.reverse().find(x => isRootHandle(x.handle))?.handle as RootHandle,
		[matches],
	);

	return (
		<rootHandleContext.Provider value={leafHandle}>
			{children}
		</rootHandleContext.Provider>
	);
}
